import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardTitle, Col, Container, Input, Row, Spinner } from "reactstrap";
import axios from 'axios';
import NavBarBottom from "./NavBarBottom";
import NavBarTop from "./NavBarTop";
import MyDeals from "./deals/MyDeals";
import SignIn from "./auth/SignIn";
import { Link, useParams } from "react-router-dom";
import DealList from "./deals/DealList";
import PopularDeals from "./deals/PopularDeals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faClose } from "@fortawesome/free-solid-svg-icons";


function Companies() {

    const { id } = useParams()
    const [user, setUser] = useState();

    const [search, setSearch] = useState("");
    const [title, setTitle] = useState("Search");

    const [loading, setLoading] = useState(false);
    const [comps, setCompanies] = useState([]);

    const fetchCompanies = async () => {
        await axios.get(`https://bulkbuy.godinein.com/public/api/rescat/${id}`).then(({ data }) => {
            setCompanies(data)
            //console.log(data)
            setLoading(true);
        })
    }

    const fetchCompaniesSer = async () => {
        await axios.get(`https://bulkbuy.godinein.com/public/api/search/${search}`).then(({ data }) => {
            setCompanies(data)
            //console.log(data)
            setLoading(true);
        })
    }

    useEffect(() => {
        fetchCompanies()
    }, [])

    return (
        <>
            <div className="bg-light">
                <Row className="sticky-top-bar" style={{ background: "linear-gradient(to bottom, black, transparent)", padding: "10px", position: "absolute", top: "0", zIndex: "100", width: "100%", '--bs-gutter-x': 0 }}>
                    <div className="col-1" style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "left" }}>
                        <a style={{ color: "#DC3545", paddingLeft: "5px" }} href="#/home"><FontAwesomeIcon size="lg" icon={faArrowLeft} /></a>
                    </div>
                    <div className="col-8">
                        <h2 style={{ textAlign: "left", marginBottom: "0px", color: "#fff" }}>{title}</h2>
                    </div>
                    <div className="col-3" style={{ textAlign: "right", border: "0px solid #000", display: "flex", alignItems: "center", justifyContent: "right" }}>
                        <a style={{ color: "#fff" }} href="#/home"><FontAwesomeIcon size="lg" icon={faClose} /></a>
                    </div>
                </Row>

                {/* <div className="row border" style={{ backgroundColor: "#FFF", padding: "10px" }}>
                    <div className="col-3" style={{ textAlign: "center", border: "0px solid #000", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <a style={{ color: "#666" }} href="#/home"><FontAwesomeIcon size="lg" icon={faArrowLeft} /></a>
                    </div>
                    <div className="col-6" style={{ paddingTop: "10px" }}>
                        <h3 style={{ color: "#666" }}></h3>
                    </div>
                    <div className="col-3" style={{ textAlign: "right", border: "0px solid #000", display: "flex", alignItems: "center", justifyContent: "right" }}>
                        <a style={{ color: "#000" }} href="#/home"><FontAwesomeIcon size="lg" icon={faClose} /></a>
                    </div>
                </div> */}

                <div className="row mydeal-box" style={{ marginBottom: "20px", marginTop:"11%" }}>
                    <div className="col-12">
                        <p style={{ padding: "0px", margin: "0px", fontSize: "14px" }}>Search</p>
                        <div className="col-12">
                            <Input type="text" onKeyUp={(event) => {
                                setSearch(event.target.value)
                                fetchCompaniesSer()
                            }} />
                        </div>
                    </div>
                </div>
                {
                    loading ?
                        comps.length > 0 && (
                            comps.map((row, key) => (
                                <Link style={{ textDecoration: "none" }} to={`/companydeals/${row.id}`}>
                                    <div className="row shadow border-radius-15 mydeal-box" style={{ marginBottom: "20px" }}>
                                        <div className="col-3">
                                            <img className="border-radius-15" style={{ width: "100%", padding: "0px" }}
                                                alt="Sample"
                                                src={row.image}
                                            />
                                        </div>
                                        <div className="col-9">
                                            <h5 style={{ color: "#2B2B2B", marginTop: "10px" }}>{row.name}</h5>
                                            <p style={{ color: "#666", fontSize: "12px", padding: "0px" }}>{row.address}</p>
                                            <p style={{ color: "#FF1843", fontSize: "12px", padding: "0px" }}>Available Deals</p>
                                        </div>
                                    </div>
                                </Link>
                            ))) : <Spinner animation="border" />
                }
            </div>
            <NavBarBottom />

        </>
    )
}
export default Companies;