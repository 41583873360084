import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardTitle, Col, Container, Input, Row, Spinner } from "reactstrap";
import axios from 'axios';
import NavBarBottom from "./NavBarBottom";
import NavBarTop from "./NavBarTop";
import MyDeals from "./deals/MyDeals";
import SignIn from "./auth/SignIn";
import { Link, useParams } from "react-router-dom";
import DealList from "./deals/DealList";
import PopularDeals from "./deals/PopularDeals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";


function PopularCompanies() {

    const { id } = useParams()
    const [user, setUser] = useState();

    const [products, setProducts] = useState([]);
    const [title, setTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [comps, setCompanies] = useState([]);

    const fetchCompanies = async () => {
        await axios.get(`https://bulkbuy.godinein.com/public/api/restaurants`).then(({ data }) => {
            setCompanies(data)
            //console.log(data)
            setLoading(true);
        })
    }

    useEffect(() => {
        fetchCompanies()

    }, [])



    return (
        <>

            <div className="row title" style={{ marginTop: "20px" }}>
                <div className="col-12">
                    <h6>Popular </h6>
                </div>
            </div>

            {
                loading ?
                    comps.length > 0 && (
                        comps.map((row, key) => (
                            <Link style={{ textDecoration: "none" }} to={`/companydeals/${row.res_id}`}>
                                <div className="row shadow border-radius-15 mydeal-box" style={{ marginBottom: "20px" }}>
                                    <div className="col-3">
                                        <img className="border-radius-15" style={{ width: "100%", padding: "0px" }}
                                            alt="Sample"
                                            src={row.image}
                                        />
                                    </div>
                                    <div className="col-9">
                                        <h5 style={{ color: "#2B2B2B", marginTop: "10px" }}>{row.res_name}</h5>
                                        <p style={{ color: "#666", fontSize: "12px", padding: "0px" }}>{row.address}</p>
                                        <p style={{ color: "#FF1843", fontSize: "12px", padding: "0px" }}>Available Deals</p>
                                    </div>
                                </div>
                            </Link>
                        ))) : <Spinner animation="border" />
            }
        </>
    )
}
export default PopularCompanies;