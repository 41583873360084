import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import NavBarBottom from "../NavBarBottom";
import NavBarTop from "../NavBarTop";
import Swal from 'sweetalert2'

function SignUp() {
    const navigate = useNavigate();

    const [first_name, setFirstName] = useState("")
    const [last_name, setLasName] = useState("")
    const [mobile, setMobile] = useState()
    const [bday, setBDay] = useState()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()

    const [passwordShown, setPasswordShown] = useState(false)

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const [validationError, setValidationError] = useState({})

    const createCustomer = async (e) => {
        e.preventDefault();

        const formData = new FormData()

        formData.append('first_name', first_name)
        formData.append('last_name', last_name)
        formData.append('mobile', mobile)
        formData.append('bday', bday)
        formData.append('email', email)
        formData.append('password', password)

        await axios.post(`https://bulkbuy.godinein.com/public/api/customers`, formData).then(({ data }) => {
            //console.log(data)
            const { user_id } = data.customer
            localStorage.setItem('user_logged_in', true)
            localStorage.setItem('user_id', user_id)
            navigate("/home")
        }).catch(({ response }) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Email Already Exists !!',
            })
        })
    }

    return (
        <>
            <Container style={{ backgroundColor: "#FFF" }} fluid>
                <div className="row" style={{ marginTop: "20px", textAlign: "center", backgroundColor: "#FFF" }}>
                    <div className="col-12">
                        <h4 style={{ color: "#192a51" }}>BulkBuyMe</h4>
                    </div>
                </div>
                <div className="row" style={{ marginTop: "20px", textAlign: "center", backgroundColor: "#FFF" }}>
                    <div className="col-12">
                        <h3>Sign up</h3>
                        <hr />
                    </div>
                </div>
                <Form onSubmit={createCustomer}>
                    <Row>
                        <Col xs={6} md={6}>
                            <FormGroup>
                                <Label for="exampleEmail">
                                    First Name
                                </Label>
                                <Input
                                    id="exampleEmail"
                                    name="email"
                                    placeholder="First Name"
                                    required
                                    value={first_name}
                                    type="text" onChange={(event) => {
                                        setFirstName(event.target.value)
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6} md={6}>
                            <FormGroup>
                                <Label for="lastname">
                                    Last Name
                                </Label>
                                <Input
                                    id="examplePassword"
                                    name="lastname"
                                    placeholder="Last Name"
                                    required
                                    type="text"
                                    value={last_name}
                                    onChange={(event) => {
                                        setLasName(event.target.value)
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} md={6}>
                            <FormGroup>
                                <Label for="exampleEmail">
                                    Mobile No
                                </Label>
                                <Input
                                    id="exampleEmail"
                                    name="email"
                                    placeholder="Mobile"
                                    type="text"
                                    required
                                    value={mobile}
                                    onChange={(event) => {
                                        setMobile(event.target.value)
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6} md={6}>
                            <FormGroup>
                                <Label for="examplePassword">
                                    Birthday
                                </Label>
                                <Input
                                    id="examplePassword"
                                    name="password"
                                    placeholder="Last Name"
                                    type="date"
                                    required
                                    value={bday}
                                    onChange={(event) => {
                                        setBDay(event.target.value)
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="exampleEmail">
                                    Email
                                </Label>
                                <Input
                                    id="exampleEmail"
                                    name="email"
                                    required
                                    placeholder="Email"
                                    type="email"
                                    value={email}
                                    onChange={(event) => {
                                        setEmail(event.target.value)
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="examplePassword">
                                    Password (<a style={{ color: "#192a51", textAlign: "right" }} className="" onClick={togglePassword}><FontAwesomeIcon size="sm" icon={faEye} /></a>)
                                </Label>
                                <Input
                                    id="examplePassword"
                                    name="password"
                                    required
                                    placeholder="New Password"
                                    type={passwordShown ? "text" : "password"}
                                    value={password}
                                    onChange={(event) => {
                                        setPassword(event.target.value)
                                    }}
                                />
                            </FormGroup>
                            {/* <a style={{position:"absolute", right:"20px", top:"51.5%", color:"#666"}} className="" onClick={togglePassword}><FontAwesomeIcon size="lg" icon={faEye} /></a> */}

                        </Col>
                    </Row>
                    <Button className="logout-button border-radius-15">
                        Sign up
                    </Button>
                </Form>
                <div className="row" style={{ marginTop: "20px", textAlign: "center", backgroundColor: "#FFF" }}>
                    <div className="col-12">
                        <hr />
                        <p style={{ color: "#666" }}>Already have an account? <a style={{ textDecoration: "none", color: "red" }} href="#/signin">Sign in</a> </p>
                    </div>
                </div>
                <NavBarBottom />
            </Container>
        </>
    )
}
export default SignUp;