import React, { useEffect, useState } from "react";
import { Container, Spinner } from "reactstrap";
import axios from 'axios';
import NavBarBottom from "../NavBarBottom";
import NavBarTop from "../NavBarTop";
import MyDeals from "./MyDeals";
import SignIn from "../auth/SignIn";
import { Link } from "react-router-dom";


function DealOptions(props) {

    const [options, setDealOptions] = useState([]);
    
    useEffect(() => {
        fetchDealOptions()
    }, [])

    const fetchDealOptions = async () => {
        await axios.get(`https://bulkbuy.godinein.com/public/api/deals_parent/${props.id}`).then(({ data }) => {
            setDealOptions(data)
        }).catch(({ response: { data } }) => { })
    }

    return (
        <>
            {
                options.length > 0 && (options.map((row, key) => (
                    <h6 style={{ color: "#FFF", fontWeight: "bold", fontSize: "16px", marginTop: "10px", marginLeft: "10px" }}>{row.title} <span style={{ color: "red", fontSize: "12px" }}>({row.saving})</span></h6>
                )))
            }
        </>
    )
}
export default DealOptions;